export default {
  hu: {
    siteName: window.location.hostname.replace(/^www\./i, '').split('.').map((v, i, a) => i < a.length - 1 ? v.toUpperCase() : v).join('.'),
    dataPrivacyLink: '/docs/data_policy_hu.pdf',
    simpleHeadline: `Cookie-k a {{i18n.siteName}} oldalain.`,
    simpleDisclaimer: (
      `A legjobb felhasználói élmény elérése érdekében a weboldalon cookie-kat használunk.
      Ezek hozzájárulnak az oldalaink működésének biztosításához, megkönnyítik az oldalak használatát.
      A cookie-k szükségesek a weblapon végzett műveletek nyomon követése és a szolgáltatások javítása,
      valamint a releváns ajánlatok megjelenítése érdekében. Valamennyi Cookie nélkülözhetetlen
      szolgáltatásaink biztosításához, egyéb cookie-k – ideértve a harmadik féltől származóakat –,
      kikapcsolhatók, azonban nélkülük oldalunk nem működik teljes értékűen, és személyre szabottan.
      A "Cookie-k elfogadása" gombra kattintva hozzájárul a cookie-k használatához.
    `
    ),
    advancedHeadline: 'Az oldal cookie-kal kapcsolatos beállításai.',
    advancedDisclaimer: (
      'Kérjük, válassza ki, hogy oldalunk milyen cookie-kat használjon, az alábbiak szerint:'
    ),
    keepTheSettingsAndClose: 'Beállítások megtartása',
    table_note_3rdparty: (
      `
      <p>
        <small>
        (●) A "harmadik fél" oszlopban megjelölt „cookie”-k nem a honlap üzemeltetője
        által felügyelt kiszolgálószerverekről származnak, így a honlap üzemeltetőjének
        kontrollja ezen „cookie”-k felett technikai értelemben csak részleges. E
        zzel kapcsolatban további információk olvashatók jelen tájékoztató harmadik
        féltől származó „cookie”-król szóló részében.
        </small>
      </p>
      `
    ),
    cookiePolicy: (
      `
        <article>

          <h1>A {{i18n.siteName}} „cookie”-kkal kapcsolatos adatkezelési irányelvei.</h1>

          <h2>Mi a "Cookie"?</h2>

          <p>
            A „cookie” (más néven „süti”, továbbiakban „cookie”) egy kis szöveges fájl,
            amely egy adott honlaphoz, illetve az azt megjelenítő böngészőhöz köthetően a
            felhasználói interakcióival kapcsolatos olyan információkat tárol, amelyeket a
            honlap készítője/készíttetője meghatároz. Ilyenek lehetnek például a honlappal
            kapcsolatos beállítások, a megjelenített tartalom felhasználói érdeklődésnek és
            igényének megfelelő kialakítása stb.
          </p>

          <p>
            Az {{i18n.siteName}} honlapon alkalmazott „cookie”-k kategóriájuktól függően lehetnek jogi
            megfogalmazással szigorúan szükséges, gyakorlatban az oldalon automatikusan használt
            „cookie”-k vagy olyanok, amelyek alkalmazásához előzetesen kifejezetten felhasználói
            hozzájárulás szükséges. Kategóriájuktól függően ezek lehetnek funkcionális vagy
            teljesítmény vagy marketing „cookie”-k.
          </p>

          <p>
            Ma már szinte minden honlap alkalmaz „cookie”-kat, amelyek célja alapvetően az, hogy a
            felhasználó részéről történő ismételt holnaplátogatások felismerhetők legyenek és ennek
            segítségével a felhasználói élmény javítható legyen. Ilyen lehet többek között a
            felhasználó által korábban már megtett beállítások - pl. a „cookie” beállítások –
            megjegyzése, hogy ennek megtételére a felhasználó legközelebbi látogatásakor már ne
            legyen szükség.
          </p>

          <h3>A cookie-k használatának általános engedélyezése/tiltása.</h3>

          <p>
            A legtöbb böngésző beállításai lehetőséget adnak arra, hogy a „cookie”-k alkalmazása
            általánosan engedélyezett vagy tiltott legyen a honlapok megtekintésekor. A korszerű
            böngészők általában a böngésző-beállításokon keresztül biztosítják a „cookie”-k
            alkalmazásának szabályozását. Bővebb információ különböző böngészők
            cookie-beállításairól a lap alján vagy a
            <a href="http://www.youronlinechoices.com/hu/" target="_blank">www.youronlinechoices.com</a>
            weboldalon is található.
          </p>

          <p>
            Fontos tudni, hogy a „cookie” alkalmazásának általános formában történő tiltása
            a böngésző-beállításokon keresztül azt is eredményezheti, hogy a honlap interaktív
            funkciói részben vagy egészben nem lesznek használhatók. Ezért célszerűbb mindig az
            adott honlap által biztosított „cookie” beállítási lehetőségekkel szabályozni a
            honlaphoz kapcsolódó „cookie”-k alkalmazását.
          </p>

          <p>
            Első látogatáskor a {{i18n.siteName}} honlaphoz kapcsolódóan a felhasználó a böngészőablak alján
            megjelenő „Cookie-k elfogadása” gombbal járulhat hozzá a „cookie”-k alkalmazásához.
            A hozzájárulás megadása előtt a „Részletes beállítások” hivatkozáson szabályozható,
            hogy mely kategóriába tartozó „cookie”-k alkalmazását engedélyezi a felhasználó.
            A „cookie-k” alkalmazásának engedélyezését követően a beállítások 1 naptári hónapig
            megjegyzésre kerülnek a böngészőben, de bármikor módosíthatók az oldal láblécében
            megtalálható „Cookie beállítások” menüpontban.
          </p>

          <p>
            <small>Megjegyzés: megtörténhet, hogy egy harmadik féltől származó „cookie” még a felhasználó
            által történő elutasítást megelőzően eltárolásra került a böngészőeszközön
            (pl. korábbi engedélyezés által stb.). Technikai okokból ilyen esetben a honlap által
            biztosított beállításokkal történő „cookie” engedélyezés folyamata során csak azok
            a „cookie”-k lesznek törölhetők, amelyek nem harmadik féltől származtak.
            A már a böngészőeszközre kerül harmadik féltől származó „cookie”-k esetén
            azok törlése a böngésző által biztosított adatkezelési beállításoknál lesz lehetséges.</small>
          </p>

          <p>
            Az alábbi táblázatok bemutatják a {{i18n.siteName}} honlapon alkalmazott „cookie”-kat,
            illetve ismertetik azok használatának célját. Az információkat rendszeresen frissítésre
            kerülnek, azonban mégis előfordulhat, hogy a tájékoztatók tartalma nincs szinkronban
            a programkódokban található információkkal. Amennyiben a felhasználó ilyen eltérést
            tapasztal, úgy nagyban segítheti a honlap készítőjének/készíttetőjének tájékoztatási
            kötelezettségét, ha ezeket az információkat a tudomásukra hozza.
          </p>



          <h2>A honlap alapműködéséhez szigorúan szükséges „cookie”-k, melyekhez nincs szükség a beleegyezésére:</h2>

          <p>
            A jogszabályok felhasználói hozzájárulás nélkül olyan „cookie”-k alkalmazását
            teszik lehetővé, amelyek hiányában technikai értelemben nem lehetne megfelelően
            és teljes értékűen használni a honlapot. Ilyen funkciók lehetnek például a honlap
            és a kiszolgálószerver közötti adatkommunikáció során a kapcsolatot szolgáló
            munkamenet azonosító „cookie” vagy a magukkal a „cookie”-k engedélyezésével
            kapcsolatban megtett felhasználói beállítások megjegyzése.
          </p>

          <h3>SZIGORÚAN SZÜKSÉGES „COOKIE”-K</h3>

          <p>
            A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:
          </p>


          {{components.cookie_table_cookies_essential}}


          <h2>Előzetes felhasználói hozzájárulást igénylő „cookie”-k:</h2>

          <p>
            Azon „cookie”-k esetében, amelyek jogi értelemben nem feltétlenül szükségesek a honlap
            működéséhez, elengedhetetlenül szükség van arra, hogy alkalmazásukhoz a felhasználó
            kifejezetten hozzájáruljon.
          </p>
          <p>
            Amennyiben a felhasználó nem járul hozzá egészében ezen „cookie”-k alkalmazásához az
            oldalon, úgy azt is elfogadja, hogy a hozzájárulásának hiányában a honlap működése
            nem minden tekintetben lesz a készítő/készíttető szándékainak megfelelően biztosított.
            Ugyanakkor a későbbiekben a felhasználónak lehetősége van bármikor és minden további
            feltétel nélkül a hozzájárulást igénylő „cookiek”-k engedélyezésével kapcsolatos
            korábbi döntését a honlapon erre vonatkozóan biztosított beállítási lehetőségekkel
            megváltoztatni.
          </p>
          <p>
            Az előzetes felhasználói hozzájárulást igénylő „cookie”-k három kategóriába sorolhatók:
          </p>
          <ul>
            <li>funkcionális „cookie”-k</li>
            <li>teljesítmény „cookie”-k</li>
            <li>marketing „cookie”-k</li>
          </ul>

          <p>
            A fentekben említett „cookie”-k alkalmazása során gyűjtött információk a felhasználó
            beazonosításra nem alkalmasak, személyhez nem köthetők, bizonyos esetekben kizárólag
            összegezve kerülnek felhasználásra. Ezen „cookie”-k által hozzáférhető információk
            feldolgozása jellemzően automatizáltan történik és a honlap fejlesztését,
            illetve a teljesítményének növelését, egyben ezek által a felhasználói élmény
            javítását szolgálják.
          </p>


          <h3>FUNKCIONÁLIS COOKIE-K.</h3>

          <p>
            A funkcionális cookie-k lehetővé teszik, hogy a honlapon a felhasználó által
            megadott egyes információk elmentésre kerüljenek, hogy az oldal jobb, személyre
            szabottabb funkciókat kínálhasson (például az oldalon kiválasztott megjelenítési
            nyelv stb.). Továbbá az ilyen jellegű „cookie”-k segítik különböző állapotok
            megőrzését az oldalon történő navigáció során, mint pl. a videólejátszó
            beállított hangereje, a lejátszás aktuális pozíciója, stb.
          </p>

          <p>A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:</p>

          {{components.cookie_table_cookies_functional}}

          {{i18n.table_note_3rdparty}}

          <h3>TELJESÍTMÉNY COOKIE-K.</h3>

          <p>
            A teljesítmény „cookie”-k a honlap statisztikai méréseihez gyűjtenek információkat
            arról, hogy a látogatók hogyan, milyen felhasználói szokások mellett és beállításokkal
            használják az oldalt. Ilyen adatok lehetnek például, hogy a felhasználó mely oldalakat
            nyitotta meg vagy az oldal mely funkcióit használta a leggyakrabban, mennyi időt
            töltött az oldalon, kapott-e esetleg hibaüzeneteket a használat során stb.
          </p>

          <p>A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:</p>

          {{components.cookie_table_cookies_performance}}
          {{i18n.table_note_3rdparty}}


          <h3>MARKETING COOKIE-K.</h3>

          <p>
            A marketing „cookie”-k gyűjtőnéven azok a „cookie”-k, amelyek első sorban a
            felhasználó érdeklődéséhez köthető hirdetések célba juttatását, célcsoportkeresést
            és a reklámozás fejlesztését szolgálják. Segítségükkel korlátozni is lehet a
            felhasználó számára történő hirdetésmegjelenések számát, illetve mérni lehet
            az online reklámkampányok hatékonyságát. Gyakran összekapcsolva működnek más,
            harmadik fél által biztosított honlapszolgáltatásokkal.
          </p>
          <p>
            A marketing „cookie”-k által gyűjtött adatok alkalmasak annak megállapítására,
            hogy a felhasználó meglátogatott egy adott honlapot és ez az információ megosztásra
            kerülhet harmadik féllel, például online hirdetési felületszolgáltatókkal.
          </p>

          <p>A {{i18n.siteName}} honlapon alkalmazott a fenti kategóriába tartozó „cookie”-k a következők:</p>

          {{components.cookie_table_cookies_marketing}}
          {{i18n.table_note_3rdparty}}

          <br />

          <h2>A harmadik fél által biztosított „cookie”-kra vonatkozó kiegészítő információk:</h2>

          <br />

          {{components.thirdparty_table}}


          <h3>Honlapba ágyazott közösségi médiához kapcsolódó szolgáltatások</h3>

          <p>
            Egy honlap a közösségi médiaplatformok (pl. Facebook, LinkedIn, Twitter stb.) által
            biztosított, oldalba ágyazható megosztási vagy más felhasználói interakció lehetőségét
            biztosító funkcióblokkot („share” vagy „megosztás” gomb stb.) tartalmazhat, amelyek
            lehetővé teszik, hogy a felhasználótól/felhasználóról információk jussanak el a
            funkcióblokkhoz köthető médiaplatformra/médiaplatformhoz. A funkcióblokkok használata
            során esetlegesen előfordulhat, hogy a médiaplatform a honlap készítője/készíttetője
            tudtán kívül „cookie”-kat helyez el a felhasználó böngészőeszközén. Ezek a „cookie”-k
            semmilyen formában nem kapcsolódnak a honlap készítőjéhez/készíttetőjéhez, akinek ezek
            működésére, adatgyűjtésére vonatkozóan semmilyen ráhatása nincs. Ezekkel a „cookie”-kal
            kapcsolatban a harmadik fél által biztosított „cookie” tájékoztatók tartalmazhatnak
            adatkezelési információt a felhasználó számára.
          </p>

          <h2>Egyéb információk</h2>
          <p>
            <b>
              A legelterjedtebb böngészők „cookie” beállítási lehetőségeivel kapcsolatban az alább
              felsorolt böngészők megnevezésére kattintva érhető el bővebb információ:
            </b>
          </p>
          <ul>
            <li><a href="https://support.apple.com/hu-hu/guide/safari/sfri11471/11.0/mac/10.13" target="_blank">Apple Safari</a></li>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=hu" target="_blank">Google Chrome</a></li>
            <li><a href="https://support.microsoft.com/hu-hu/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a></li>
            <li><a href="https://support.microsoft.com/en-ie/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank">Microsoft Edge</a></li>
            <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a></li>
            <li><a href="https://support.mozilla.org/hu/kb/sutik-engedelyezese-es-tiltasa-amit-weboldak-haszn" target="_blank">Mozilla Firefox</a></li>
          </ul>

          <br />

          <p>
            <b>Általánosan a „cookie”-kkal kapcsolatos további átfogó és részletes információk
            találhatók az alábbi hivatkozáson elérhető online adatvédelmi és viselkedés
            alapú hirdetési útmutatóban:</b>
          </p>

          <p><a href="http://www.youronlinechoices.com" target="_blank">www.youronlinechoices.com</a></p>

          <p>Ilyen információk például, hogy miként tekinthetők meg és rendezhetők,
            illetve törölhetők a böngészőben elmentett „cookie”-k stb.
          </p>

        </article>
      `
    ),
    cat_on: 'Be',
    cat_off: 'Ki',
    btn_accept: 'Cookie-k elfogadása',
    btn_reject: 'Elutasítás',
    btn_close: '+',
    btn_close_tile: 'Bezárás',
    btn_privacy: 'Adatkezelési tájékoztató',
    btn_cookies: 'Cookie tájékoztató',
    btn_privacy_advanced: 'Adatkezelési tájékoztató',
    btn_save_advanced: 'Beállítások elfogadása',
    btn_advanced: 'Részletes beállítások',
    table_name: 'Cookie név',
    table_cookies: 'Cookie-k',
    table_provider: 'Szolgáltató',
    table_domain: 'Domain',
    table_thirdparty: 'Harmadik fél',
    table_purpose: 'Felhasználás',
    table_source: 'Forrás',
    table_type: 'Típus',
    table_expiry: 'Lejárat',
    table_link: 'Hivatkozás',
    table_description: 'Leírás',
    table_link_opt_out: 'Leiratkozás',
    table_link_cookie_policy: 'Cookie-k',
    table_link_data_policy: 'Adatkezelés',
    table_empty_category: 'Jelenleg nincs ebbe a kategóriába besorolt cookie.',
    cookie_essential_label: 'Szigorúan szükséges cookie-k',
    cookie_essential_desc: 'Ezen cookie-k biztosítják a weboldal megfelelő működését és segítik annak használatát.',
    cookie_essential_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul>
        <li>Munkamenet (session) cookie-k</li>
      </ul>
      `
    ),
    cookie_functional_label: 'Funkcionális cookie-k',
    cookie_functional_desc: 'Ezen cookie-k segítenek megőrizni a látogató preferenciáit, pl. oldalak navigációja között megőrzik az állapotot.',
    cookie_functional_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul>
        <li>Munkamenet kezelés</li>
        <li>Beállítások, pl. a választott nyelv megjegyzésére</li>
      </ul>
      `
    ),
    cookie_performance_label: 'Teljesítmény cookie-k',
    cookie_performance_desc: 'Ezen cookie-k úgy járulnak hozzá a weboldal továbbfejlesztéséhez, hogy elemzési célú információszerzést tesznek lehetővé.',
    cookie_performance_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul>
        <li>Munkamenet kezelés</li>
        <li>Beállítások megjegyzésére</li>
        <li>Oldal használatának elemzése jobb tartalmak előállítása érdekében</li>
      </ul>
      `
    ),
    cookie_marketing_label: 'Marketing cookie-k',
    cookie_marketing_desc: 'Ezen cookie-k a weboldal felhasználói szintű viselkedési adatait gyűjtik, hogy releváns ajánlatokat kínálhassunk.',
    cookie_marketing_detail: (
      `
      <h2>Cookie-k alkalmazása:</h2>
      <ul>
        <li>Munkamenet kezelés</li>
        <li>Beállítások megjegyzésére</li>
        <li>Oldal használatának elemzése jobb tartalmak előállítása érdekében</li>
        <li>Tartalmak és marketing relevanciájuk alakítása érdeklődés alapján</li>
      </ul>
      `
    )
  },
  en: {
    siteName: window.location.hostname.replace(/^www\./i, '').split('.').map((v, i, a) => i < a.length - 1 ? v.toUpperCase() : v).join('.'),
    dataPrivacyLink: '/assets/docs/data_policy_en.pdf',
    simpleHeadline: `COOKIES ON THIS WEBSITE.`,
    simpleDisclaimer: (
      `
      When you visit or interact with {{i18n.siteName}}, we may collect information by using cookies.
      They help us give you the best possible experience, show you the most relevant features -
      and make it easier for you to use the website. Some cookies are essential and we can't provide
      all our services without them. Other cookies, including those placed by third parties, can be
      turned off - although our site might not work quite as well without them, and content might
      not be tailored to your interests. By clicking the Accept button or by simply continuing to
      use our website, you are agreeing to our use of cookies. You can visit our cookie policy
      page to find out more about them - and to change your preferences at any time.
      `
    ),
    advancedHeadline: 'YOUR COOKIE SETTINGS ON THIS WEBSITE.',
    advancedDisclaimer: (
      'Please choose whether this site may use Functional and/or Marketing cookies, as described below:'
    ),
    keepTheSettingsAndClose: 'Accept Cookies',
    table_note_3rdparty: (
      `
      <p>
        <small>
        (●) Cookies marked with * in the source column are Third-party cookies.
        </small>
      </p>
      `
    ),
    cookiePolicy: (
      `
        <article>

          <h1>{{i18n.siteName}} COOKIE POLICY.</h1>

          <h2>WHAT IS A COOKIE?</h2>

          <p>
            A cookie is a small file that stores Internet settings. Almost every website uses cookie
            technology. It is downloaded by your Internet browser on the first visit to a website.
            The next time this website is opened with the same user device, the cookie and the
            information stored in it is either sent back to the website that created it
            (first-party cookie) or sent to another website it belongs to (third-party cookie).
            This enables the website to detect that you have opened it previously with this browser
            and in some cases to vary the displayed content.
          </p>

          <p>
            Some cookies are extremely useful, as they can improve the user experience on opening
            a website that you have already visited a number of times. Provided you use the same
            user device and the same browser as before, cookies remember for example your
            preferences, how you use a site, and adapt the displayed offerings to be more
            relevant to your personal interest and needs.
          </p>

          <p>
           Based on what function cookies have and the purpose for which cookies are used,
           there are four categories of cookie: strictly necessary cookies, performance cookies,
           functional cookies and marketing cookies.
          </p>

          <h3>Managing and deleting cookies.</h3>

          <p>
            If you want to block or delete cookies, you can make these changes in the browser
            settings. To enable you to manage cookies, most browsers allow you to accept or reject
            all cookies or only to accept certain types of cookies. You can also decide that you
            want to be asked every time a website wishes to save a cookie. The procedures for
            managing and deleting cookies differ from browser-to-browser. To find out how to do
            this in a particular browser, you can use the integrated Help function in the browser
            or, alternatively, call
            <a href="http://www.aboutcookies.org" target="_blank">www.aboutcookies.org</a>.
            This contains a step-by-step explanation of how to manage and delete cookies in the most
            common browsers.
          </p>

          <p>
            If you have any questions about our online activities, please use our
            <a href="mailto:dev@digitalwave.hu">e-mail contact</a>.
          </p>

          <p>
            Please note: It is possible that cookies have been stored on your device before your
            revocation. Due to technical reasons, we can delete parts of the cookies - especially
            those set by us - but not all cookies that are stored on your device. Your browser
            gives you the possibility to delete those cookies in the privacy setting of your
            browser.
          </p>

          <p>
            Depending on their function and intended purpose, cookies can be divided into four
            categories*: essential cookies, performance cookies, functional cookies, cookies for
            marketing purposes.
          </p>

          <h3>Cookies that are not subject to approval on this website:</h3>

          <p>
            Essential cookies, also known as "strictly necessary" cookies, guarantee functions
            without which you cannot use this website as intended. These cookies are exclusively
            used by {{i18n.siteName}} and are therefore so-called “first party cookies”. They are only stored
            on your computer during the current browser session. For example, when you call up
            the vehicle configurator, strictly necessary cookies ensure that you see a version
            whose bandwidth-based data volume corresponds to the bandwidth of your internet
            connection. In addition, such cookies ensure a smooth switch from http to https,
            for example, guaranteeing compliance with elevated security requirements for data
            transfer. Last but not least, a cookie like this is also used to save your decision
            in relation to cookies on our website. Your approval is not absolutely necessary in
            order to use strictly necessary cookies. Strictly necessary cookies cannot be
            deactivated using a function on this page. In general it is possible to deactivate
            cookies in your browser at any time.
          </p>

          <h2>STRICTLY NECESSARY COOKIES.</h2>

          <p>
            Strictly necessary cookies are essential in order to enable you to move around the
            website and use its features. Without these cookies, some services cannot be provided -
            for example, remembering previous actions (e.g. entered text and vehicle configurations)
            when navigating back to a page in the same session.
          </p>

          {{components.cookie_table_cookies_essential}}

          <h3>COOKIES THAT ARE SUBJECT TO APPROVAL ON THIS WEBSITE:</h3>

          <p>
            Cookies that are not absolutely necessary in the strict legal sense fulfil important
            tasks. Without these cookies, functions that allow you to surf with comfort on our
            website, such as pre-completed forms, will no longer be available. The settings
            you make, such as language selection, cannot be saved and therefore have to be
            checked on every page. In addition, we no longer have the option of making you
            individually customised offers. These cookies are used by {{i18n.siteName}} and are known as
            first party cookies, but can also be used by third party providers.
          </p>

          <h2>FUNCTIONAL COOKIES.</h2>

          <p>
            Enable a website to save information which has already been entered (such as user
            names, languages choices, and your location), so that it can offer you improved
            and more personalised functions. For example, a website can offer your local,
            price-relevant tax information if it uses a cookie to remember the region in
            which you are currently located. Functional cookies are also used to enable
            features you request such as playing videos. These cookies collect anonymous
            information and cannot track your movements on other websites.
          </p>

          {{components.cookie_table_cookies_functional}}

          <h2>PERFORMANCE COOKIES.</h2>

          <p>
            Gather information about how a website is used - for example, which pages a visitor
            opens most often, and whether the user receives error messages from some pages.
            These cookies do not save information that would allow the user to be identified.
            The collected information is aggregated, and therefore anonymous. These cookies
            are used exclusively to improve the performance of the website, and with it the
            user experience.
          </p>

          {{components.cookie_table_cookies_performance}}

          <h2>MARKETING COOKIES.</h2>

          <p>
            Are used to deliver adverts and other communications more relevant to you and
            your interests. They are also used to limit the number of times you see an
            advertisement and to help measure the effectiveness of advertising campaigns. They
            remember whether you have visited a website or not, and this information can be
            shared with other organisations such as advertisers (this includes advertising
             technologies on websites such as Facebook, LinkedIn and Twitter). Cookies for
            improving group targeting and advertising will often be linked to site
            functionality provided by other organisations.
          </p>

          {{components.cookie_table_cookies_marketing}}

          {{i18n.table_note_3rdparty}}

          <br />

          <h3>Use of Facebook social plugins on {{i18n.siteName}}</h3>

          <p>
            One of the features of the website {{i18n.siteName}} is that it
            uses what are called social plugins ("plugins") from the social
            networks Facebook, Twitter, Google+ and Instagram. These plugins are
            indicated by the respective logo of the social network. When you access the
            domain {{i18n.siteName}} your browser establishes a direct connection
            with the servers of these social networks. The content of the
            plugin is transferred by the social network directly to your
            browser, which then integrates it into the website. integration
            of the plugin causes Facebook, for example, to receive the
            information that you have loaded the corresponding page of this website.
            If you are logged in with Facebook, it will be able to assign your visit
            to your Facebook account. Please note that an exchange of this information
            already takes place when you visit our website, regardless of whether you
            interact with the plugin or not. If you interact with the plugins, such as
            by pressing the "Like" button, the corresponding information is sent directly
            to Facebook by your browser and saved there. You can find information on the
            purpose and extent of data acquisition as well as how the data is processed
            further and used by the social networks, together with your rights and optional
            settings to protect your private sphere, in the data protection notes of the
            social networks.
          </p>

          <h2>USING THIRD-PARTY COOKIES ON THIS WEBSITE:</h2>

          <p>
            {{i18n.siteName}} has integrated content belonging to third parties on this website.
            An example of this is the incorporation of Facebook services and YouTube videos.
            These Third-party providers can in theory place cookies while you visit the
            {{i18n.siteName}} website, and in doing so can obtain information about the fact that you have
            visited a {{i18n.siteName}} website. Please visit the websites belonging to those Third-party
            providers if you need more information about how they use cookies. If you do not
            want to authorize cookies, then you can always disable them in your browser.
            Services Privacy Policies for third party cookies can be found under:
          </p>

          <h3>THIRD-PARTY COOKIES</h3>

          {{components.thirdparty_table}}

          <p>
            <b>
              If you do not want to accept cookies at all, then you can also configure that in your browser:
            </b>
          </p>

          <ul>
            <li><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/" target="_blank">Apple Safari</a></li>
            <li><a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">Google Chrome</a></li>
            <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a></li>
            <li><a href="https://support.microsoft.com/en-gb/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy" target="_blank">Microsoft Edge</a></li>
            <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">Opera</a></li>
            <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Mozilla Firefox</a></li>
          </ul>

          <p>
            It is important to note that if you change your settings and block certain cookies,
            you will not be able to take full advantage of some features of our site, and we might
            not be able to provide some features you have previously chosen to use.
          </p>

          <br />

          <p>
            <b>You can find further useful information about cookies on these websites:</b>
          </p>

          <ul>
            <li><a href="http://www.youronlinechoices.com" target="_blank">www.youronlinechoices.com</a></li>
            <li><a href="http://ec.europa.eu/ipg/basics/legal/cookies/index_en.htm" target="_blank">ec.europa.eu</a></li>
          </ul>

        </article>
      `
    ),
    cat_on: 'On',
    cat_off: 'Off',
    btn_accept: 'Accept cookies',
    btn_reject: 'Disable cookies',
    btn_close: '+',
    btn_close_tile: 'Close',
    btn_privacy: 'Privacy policy',
    btn_cookies: 'Cookie policy',
    btn_privacy_advanced: 'Privacy policy',
    btn_save_advanced: 'Accept cookies',
    btn_advanced: 'Advanced settings',
    table_name: 'Name of cookie',
    table_cookies: 'Cookies',
    table_provider: 'Third-party provider',
    table_domain: 'Domain',
    table_thirdparty: 'Third-party',
    table_purpose: 'Purpose',
    table_source: 'Source',
    table_type: 'Type',
    table_expiry: 'Expiration',
    table_link: 'Url',
    table_description: 'Description',
    table_link_opt_out: 'Opt-Out',
    table_link_cookie_policy: 'Cookie policy',
    table_link_data_policy: 'Privacy policy',
    table_empty_category: 'There are no cookies in this category.',
    cookie_essential_label: 'STRICTLY NECESSARY COOKIES',
    cookie_essential_desc: 'These cookies ensure that the website functions properly.',
    cookie_essential_detail: (
      `
      <h2>Using Cookies for:</h2>
      <ul>
        <li>Session management</li>
      </ul>
      `
    ),
    cookie_functional_label: 'FUNCTIONAL COOKIES',
    cookie_functional_desc: 'These cookies are used to capture and remember user preferences in this websites, enhance their usability.',
    cookie_functional_detail: (
      `
      <h2>Using Cookies for:</h2>
      <ul>
        <li>Session management</li>
        <li>Remembering settings on the website like chosen language</li>
      </ul>
      `
    ),
    cookie_performance_label: 'PERFORMANCE COOKIES',
    cookie_performance_desc: 'These cookies allow us to analyze site usage so we can measure and improve performance of our sites.',
    cookie_performance_detail: (
      `
      <h2>Using Cookies for:</h2>
      <ul>
        <li>Session management</li>
        <li>Remembering settings on the website like chosen language</li>
        <li>Analyze site usage to provide better content experiences</li>
      </ul>
      `
    ),
    cookie_marketing_label: 'MARKETING COOKIES',
    cookie_marketing_desc: 'These cookies are used to help better tailor advertising to your interests, both within and beyond this websites.',
    cookie_marketing_detail: (
      `
      <h2>Using Cookies for:</h2>
      <ul>
        <li>Session management</li>
        <li>Remembering settings on the website like chosen language</li>
        <li>Analyze site usage to provide better content experiences (full-featured)</li>
        <li>Allows personalization of page content and marketing relevant to your interests.</li>
      </ul>
      `
    )
  }
};
