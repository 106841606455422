
export default class Cookies {
  constructor() {
    this.lastCookies = null;
  }

  set(name, value, _options) {
    var cookie = name + "=" + value;

    _options = _options && typeof _options === 'object' ? _options : {};

    if (_options.expire) {
      var d = _options.expire instanceof Date ? _options.expire : new Date();

      if (typeof _options.expire === 'number') {
        d.setTime(d.getTime() + _options.expire * 24 * 60 * 60 * 1000);
      }
      cookie += "; expires=" + d.toGMTString();
    }
    if (typeof _options.domain === 'string' && _options.domain) {
      cookie += "; domain=" + _options.domain;
    }
    if (typeof _options.path === 'string' && _options.path) {
      cookie += "; path=" + _options.path;
    }
    if (_options.secure) { cookie += '; secure'; }
    if (_options.httponly) { cookie += '; httponly'; }

    document.cookie = cookie;
  }

  getAll() {
    var cookies = document.cookie.split(/;\s+?/);
    var all = {}, i = 0, c;

    for (; i < cookies.length; i++) {
      if (cookies[i]) {
        c = cookies[i].split('=');
        all[decodeURIComponent(c[0] + '')] = decodeURIComponent(c[1] || '');
      }
    }
    return (this.lastCookies = all);
  }

  get(name) {
    var all = this.getAll();
    if (typeof all[name] !== 'undefined') {
      return all[name];
    }
    return null;
  }

  remove(name, domain, path) {
    this.set(name, "", {
      expire: -1,
      domain,
      path
    });
  }
}
